import * as React from "react";
import Layout from '../components/layout';
import { graphql } from 'gatsby';
import _ from 'lodash';
import About from "../components/about";
import SimpleBanner from "../components/simpleBanner";
import Seo from "../components/seo";// da
import Contact from "../components/contact";
import CookieConsent from "react-cookie-consent";
import { Link } from "gatsby";

// markup
const IndexPage = ({ data }) => {
  let siteData = _.merge( _.pick(data.siteMetadata.siteMetadata,['description','title','logo','siteUrl','posturl','captchaurl','tagLine','cookieDebug']))

  let bannerData = { title: siteData.title, subtitle:  "Page Not Found 404", image: data.image  }


  return (
      <Layout header="home" >
               <Seo  title={siteData.title}  keywords={["Error Page", siteData.tagLine]} />
               <CookieConsent location="bottom"  buttonText="Yes, I Agree" declineButtonText="Decline"  cookieName="Viewdeck-CookieConsent"
                 style={{ background: "#2B373B" }}  buttonStyle={{ color: "#4e503b", fontSize: "13px" }} expires={150} debug={siteData.cookieDebug}
                 >
                 We use cookies to improve your experience on our site and to show you relevant information. This is further detailed in our{" "}
                 <Link to="/cookie-policy"><span>Cookie Policy</span></Link>{" "}and{" "} <Link to="/cookie-policy"><span>Privacy Policy</span></Link>.{" "}
               </CookieConsent>
               <SimpleBanner bannerData={bannerData}></SimpleBanner>
      </Layout>
  )
}


//******************************************************************************


export const query = graphql`
   query {

     siteMetadata: site(siteMetadata: {}) {
       siteMetadata {
         description
         logo
         siteUrl
         title
         posturl
         captchaurl
         tagLine
         cookieDebug
       }
     }
     image: file(relativePath: {glob: "background.png"}) {
       relativePath
       childImageSharp {
         gatsbyImageData
       }
     }
  }
`


export default IndexPage
