import React from "react";
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"
//import MDRender from "./mdrender"
import { nanoid } from 'nanoid';
import _ from 'lodash';
import { useStaticQuery,graphql } from "gatsby";
import PropTypes from "prop-types";

const SimpleBanner = ({bannerData}) => {
  //console.debug(bannerData)
  const image =  getImage(bannerData.image)
  //console.debug( image )
  return(
      <div className="banner">
        <GatsbyImage alt={bannerData.title}  image={image}  />
        <div className="container">
          <div className="banner-details">
            <h1>{bannerData.title}</h1>
            <h2>{bannerData.subtitle}</h2>
          </div>
        </div>
      </div>
)};


SimpleBanner.defaultProps = {
  bannerData: { title: "", subtitle: "", image: {} }
}

SimpleBanner.propTypes = {
  bannerData: PropTypes.object
}

export default SimpleBanner;
